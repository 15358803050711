/*

  使用 CSS 的 media query 控制 DOM 是否需要渲染，以節省資源

  用例：
  <div v-viewport-condition="'> md'">
    電腦版才會顯示的內容
  </div>

  NOTE: 存取以下屬性很吃資源
  document.documentElement.clientWidth
  window.innerWidth

*/

// > 768
// > md
// < md

const breakpoints = {
  sm: '35.5em', // 568
  md: '48em', // 768
  lg: '64em', // 1024
  xl: '80em' // 1280
}

const makeMediaQuery = (queryString) => {
  const query = Object.keys(breakpoints).reduce((query, point) => query.replace(point, breakpoints[point]), queryString)
  const pattern = new RegExp(`([<>])\\s*([\\d.]+)(px|em)`)
  const matches = query.match(pattern)
  if (matches) {
    if (matches[1] === '>') {
      return `all and (min-width: ${matches[2]-0.001}${matches[3]})`
    }
    if (matches[1] === '<') {
      return `all and (max-width: ${matches[2]-0.001}${matches[3]})`
    }
    return pattern
  } else {
    throw 'Invalid media query pattern: ' + queryString
  }
}

export default {
  install(Vue) {
    Vue.directive('viewport-condition', {
      bind: function (el, binding, vnode) {
        const current = {
          visible: false,
          queryList: null,
          vnode: null,
        }
        vnode.viewportToggleVisible = (event) => {
          current.visible = event.matches
          if (!current.visible) {
            // replace HTMLElement with comment node
            const comment = document.createComment(' ');
            Object.defineProperty(comment, 'setAttribute', {
              value: () => undefined,
            });
            current.comment = comment
            current.vnode = Object.assign({}, vnode)
            vnode.elm = comment;
            // vnode.text = ' ';
            vnode.isComment = true;
            // vnode.context = undefined;
            // vnode.tag = undefined;
            // vnode.data.directives = undefined;
    
            if (vnode.componentInstance) {
              vnode.componentInstance.$el = comment;
            }
            if (el.parentNode) {
              el.parentNode.replaceChild(comment, el);
            }
          } else if (current.visible && current.comment) {
            vnode = Object.assign(vnode, current.vnode)
            if (vnode.componentInstance) {
              vnode.componentInstance.$el = current.vnode.elm;
            }
            if (current.comment.parentNode) {
              current.comment.parentNode.replaceChild(el, current.comment);
            }
          }
        }
        vnode.context.$nextTick(() => {
          vnode.viewportQueryList = window.matchMedia(makeMediaQuery(binding.value))
          vnode.viewportQueryList.addListener(vnode.viewportToggleVisible)
          vnode.viewportToggleVisible(vnode.viewportQueryList)      
        })
      },
      unbund(el, binding, vnode){
        vnode.viewportQueryList.removeListener(vnode.viewportToggleVisible)
      }
    })
  }
}
export default {
  install(Vue, options) {
    const notAvailable = '–'

    function isValidNumber(v) {
      return !isNaN(v) && v != null && v !== ''
    }

    Vue.filter('percentage', function(value, precision = 0) {
      if (!isValidNumber(value)) {
        return notAvailable
      }
      try {
        return (+value * 100).toFixed(precision).toString().replace(/^-([0\.]+)$/, '$1').replace(/\B(?=(\d{3})+\b)/g, ',') + '%'
      } catch (e) {
        console.warn(e)
        return notAvailable
      }
    })

    Vue.filter('percentageWise', function(value, precision = 0) {
      if (!isValidNumber(value)) {
        return notAvailable
      }
      // 不要顯示0%，當<1%時，顯示<1%
      if ((+value * 100).toFixed(precision) == 0 && +value > 0) {
        return `< ${1 / Math.pow(10, precision)}%`
      }
      if ((+value * 100).toFixed(precision) == 0 && +value < 0) {
        return `> -${1 / Math.pow(10, precision)}%`
      }
      try {
        return (+value * 100).toFixed(precision).toString().replace(/^-([0\.]+)$/, '$1').replace(/\B(?=(\d{3})+\b)/g, ',') + '%'
      } catch (e) {
        console.warn(e)
        return notAvailable
      }
    })

    Vue.filter('number', function(value, precision) {
      return parseFloat(value) || 0
    })

    Vue.filter('decimal', function(value, precision) {
      return (+value).toFixed(precision || 0).toString().replace(/\B(?=(\d{3})+\b)/g, ',')
    })

    function round(value, digits = 0) {
      if (!isValidNumber(value)) { return notAvailable }
      value = (+value).toFixed(digits)
      value = isValidNumber(value) ? (value) : notAvailable
      if (digits === '~0') { digits = Math.abs(value) < 1 ? 1 : 0 }
      return value
    }

    /*
      wiseRound(0.03, 1) => "0.1"
      wiseRound(0.003, 1) => "< 0.1"
    */
    function wiseRound(value, digits = 0) {
      if (!isValidNumber(value)) { return notAvailable }
      let processed = (+value).toFixed(digits)
      processed = isValidNumber(processed) ? (processed) : notAvailable
      if (parseFloat(processed) === 0 && value > 0) {
        return `< ${Math.pow(10, digits * -1)}`
      }
      if (parseFloat(processed) === 0 && value < 0) {
        return `> -${Math.pow(10, digits * -1)}`
      }
      return processed
    }

    Vue.filter('round', round)
    Vue.filter('wiseRound', wiseRound)

    Vue.filter('currency', function(value, digits = 0) {
      if (value == null) { return notAvailable }
      if (digits === '~0') { digits = Math.abs(value) < 1 ? 1 : 0 }
      return (+value).toFixed(digits).split(/\./).map((p, i) => i == 0 ? p.replace(/\B(?=(\d{3})+\b)/g, ',') : p).join('.')
    })

    Vue.filter('timeformat', function(value, format) {
      return moment(value).format(format)
    })

    Vue.filter('date', function(value) {
      return value ? moment(value).format('YYYY-MM-DD') : notAvailable
    })

    Vue.filter('currencyName', function(value) {
      const list = {
        TWD: '新台幣',
        USD: '美元',
        GBP: '英鎊',
        EUR: '歐元',
        AUD: '澳幣',
        CNY: '人民幣',
        ZAR: '南非幣',
        JPY: '日圓',
        HKD: '港幣',
        SGD: '星元',
        NZD: '紐元',
        CAD: '加元',
        SEK: '瑞典克朗',
        NOK: '挪威克朗',
        PLN: '波蘭茲羅提',
        CHF: '瑞士法郎',
        CNH: '離岸人民幣',
        GBX: '便士純正',
        INR: '印度盧比',
        RUB: '俄羅斯盧布',
      }
      return value ? list[value] : notAvailable
    })
  }
}

const desktop = window.matchMedia('all and (min-width: 768px)')
const mobile = window.matchMedia('all and (max-width: 767px)')
const tablet = window.matchMedia('all and (max-width: 1024px)')
const wider = window.matchMedia('all and (min-width: 1440px)')

export default {
  install(Vue, options) {
    const deviceView = Vue.observable({
      sm: mobile.matches,
      mobile: mobile.matches,
      tablet: tablet.matches,
      md: desktop.matches,
      desktop: desktop.matches,
      wider: wider.matches,
    })

    mobile.addListener((event) => {
      deviceView.sm = event.matches
      deviceView.mobile = event.matches
    })

    desktop.addListener((event) => {
      deviceView.md = event.matches
      deviceView.desktop = event.matches
    })

    tablet.addListener((event) => {
      deviceView.tablet = event.matches
    })

    wider.addListener((event) => {
      deviceView.wider = event.matches
    })

    Object.defineProperty(Vue.prototype, '$deviceView', {
      get() {
        return deviceView
      },
    })
  },
}

import VueRouter from 'vue-router'
/**
 * 定義前端的頁面路由
 */
const url = location.href
const rool = url.search('janny.demo') !== -1 ? '/kymco/' : '/';

const router = new VueRouter({
  base: document.querySelector('[routes-base]')?.getAttribute('routes-base') ?? process.env.PUBLIC_PATH_BASE ?? `${rool}`,
  mode: 'history',
  linkActiveClass: 'is-active router-link-active',
  linkExactActiveClass: 'is-active router-link-exact-active',
  routes: [
    {
      name: 'Home',
      path: '/(2022)?',
      component: () => import(/* webpackChunkName: "page-home" */ 'pages/home/2022.vue'),
    },
    {
      name: 'Catalogue', //父元件
      path: '/catalogue(-new)?/',
      component: () => import(/* webpackChunkName: "page-catalogue" */ 'pages/catalogue/index.vue'),
      children: [
        {
          path: '',
          redirect: 'capacity',
        },
        {
          name: 'CatalogueCapacity', //子元件A
          path: 'capacity/:current?',
          component: () => import(/* webpackChunkName: "page-catalogue" */ 'pages/catalogue/byCapacity.vue'),
        },
        {
          name: 'CatalogueStyle', //子元件B
          path: 'style/:current?',
          component: () => import(/* webpackChunkName: "page-catalogue" */ 'pages/catalogue/byStyle.vue'),
        }
      ]
    },
    {
      name: 'Compare',
      path: '/compare/:models?',
      component: () => import(/* webpackChunkName: "page-compare" */ 'pages/compare/index.vue'),
    },
    {
      name: 'Motor',
      path: '/motor/:slug?',
    },
    {
      name: 'Manuals',
      path: '/manuals/:query?',
      component: () => import(/* webpackChunkName: "page-manuals" */ 'pages/manuals.vue'),
    },
    {
      name: 'Customer',
      path: '/customer/',
      component: () => import(/* webpackChunkName: "page-customer" */ 'pages/customer/index.vue'),
    },
    {
      name: 'CustomerContact',
      path: '/customer/contact',
      component: () => import(/* webpackChunkName: "page-customer" */ 'pages/customer/contact.vue'),
    },
  ]
})

/**
 * 解决报错问题：报错显示是路由重复
 * Error: Avoided redundant navigation to current location
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // Ignore the vuex err regarding  navigating to the page they are already on.
    if (
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
    ) {
      // But print any other errors to the console
      throw err;
    }
  });
}


export default router
